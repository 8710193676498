

import React,{Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import PreviousIcon from "../Utilities/imgs/complete.png"

class LockedTab extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
    }    
}

componentDidMount(){
}

render() {
// const {} =this.state;

return (
        <div className='body-container'>
            <div className='inner-body-container'>
                <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                        <div className="card-body">
                            
                                <Alert variant="danger">
                                    <Alert.Heading><b><p className='text-danger'>This Tab Is Locked !!</p></b></Alert.Heading>
                                    <p className='text-danger'>Complete All The  Previous Tabs with The Required Inputs </p>
                                    <img  src={PreviousIcon} alt='Previous ' className='previous-tab-icon'/>
                                    <hr />
                                    <p className="mb-0">It Will be Active after Completing The Previous Tabs  </p>
                                </Alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(LockedTab);