

import React,{Component} from 'react';
import axios from 'axios';

// redux
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import NextIcon from "../Utilities/imgs/next.png"
import Header from './Header';
import { APIResearchesGet, } from '../Utilities/APIS';
import { getIdCategory, getIdFullName } from '../Utilities/Functions';


class Projects extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        

    }    
}

componentDidMount() {
    // this.loadData();
    // this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
    }
};

render() {
    const {AreasData,FilteredData} =this.state;

return (
            <div className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Projects </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                    <Button className='black-bg-color' >
                                        Submit Project
                                    </Button>
                            </ButtonGroup> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">On Going Project Details</h5>
                                <p>Project Details Here</p>
                            </div>
                        </div>

                        </div>

                        <div class="col-lg-6">

                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">Project Details</h5>
                                <p>Project Details Here</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Projects);
