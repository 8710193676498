
var BaseUrl = "https://java.mogahenze.com";

// var BaseUrl = "http://178.79.156.99:8282";

// var BaseUrl = "http://localhost:8282";



export const APIResearchPost = BaseUrl+"/api/research/create";
export const APIResearchesGet = BaseUrl+"/api/research/list/all";
export const APIEmailsList = BaseUrl+"/api/list/emails";
export const APIResearchRegisterPost = BaseUrl+ "/api/researchers/create";
export const APIResearchersList = BaseUrl+"/api/researchers/list/all";
export const APIUniversitiesGet = BaseUrl+"/api/universities/list/all";
export const APIAreasGet = BaseUrl+"/api/areas/list/all";
export const APIResearcherGetById = BaseUrl+"/api/research/by/researcherName/";
export const APIUpdateResearcher = BaseUrl+"/api/researcher/update/";
export const APIUpdateResearcherPassword = BaseUrl+"/api/researcher/password/update/";
export const APIResearchSimilarity = BaseUrl+"/api/research/check/similarity";

export const APIResearcherGetByEmail = BaseUrl+"/api/list/researcher/by/email/";
export const APIResearcherById = BaseUrl+"/api/list/researcher/by/id/";

export const APIResearchViewsPost = BaseUrl+"/api/research/views/create";
export const APIResearchViewsByName = BaseUrl+"/api/research/views/by/researcherName/";



export const APIResearcherForgotPassword = BaseUrl+"/api/researcher/forgot/password";
export const APIResearcherPasswordRest = BaseUrl+"/api/researcher/password/reset";