

import React,{Component} from 'react';
import axios from 'axios';
// import ReactPaginate from 'react-paginate';
// redux
// import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import NextIcon from "../Utilities/imgs/next.png"
import Header from './Header';
import { APIResearchersList, APIResearchesGet, APIResearchViewsPost, } from '../Utilities/APIS';
import { getCurrentUserId, getIdCategory, getIdFullName, getTodaysDate } from '../Utilities/Functions';


class Researches extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchesData:[],
        AreasData:[],
        FilteredData:[],
        PaginateFilteredData:[],
        ResearchersData:[],

        // pagination
        recordsPerPage:10,
        pageNumber:0,
        

    }    
}

componentDidMount() {
    this.loadData();
    this.initializeReduxData();
    // setTimeout(()=>this.setPaginateFilteredData(),3000);
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
    }
};

loadData = () =>{

    axios.get(APIResearchesGet)
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({FilteredData:jsonResults})
            this.setState({ResearchesData:jsonResults})
        })
    .catch(err=>{console.log(err);})

    axios.get(APIResearchersList)
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}


filterDataByArea = (id) => {
    let code = String(id)
    let filteredArray = this.state.ResearchesData.filter(item => item.researchArea === code);
    this.setState({FilteredData:filteredArray})
    // this.setState({FilteredData:filteredArray.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})})

    // console.log("this.state.FilteredData LEN"+ this.state.FilteredData.length)
};

goToDetailsScreen = (
        uploadDate,Topic,Name,Area,Status,pdfFile,

        researchAbstract,
        researchMainObjectives,
        researchSpecificObjectives,
        researchHypothesis,
        researchTheoreticalFrame,
        researchLiteratureReview,
        researchMethodology,
        researchResults,
        researchDiscussionOfResults,
        researchRecommendations,
        researchConclusions,
        
    ) =>{
        // post user post
    this.postUserViewOfResearch (pdfFile,Topic,Name,Area,Status,uploadDate)

    let detailsProps = {
        researchTopic:Topic,
        researcherName:Name,
        researchArea:Area,
        researchStatus:Status,
        pdfFile:pdfFile,


        researchAbstract:JSON.parse(researchAbstract),
        researchMainObjectives:JSON.parse(researchMainObjectives),
        researchSpecificObjectives:JSON.parse(researchSpecificObjectives),
        researchHypothesis:JSON.parse(researchHypothesis),
        researchTheoreticalFrame:JSON.parse(researchTheoreticalFrame),
        researchLiteratureReview:JSON.parse(researchLiteratureReview),
        researchMethodology:JSON.parse(researchMethodology),
        researchResults:JSON.parse(researchResults),
        researchDiscussionOfResults:JSON.parse(researchDiscussionOfResults),
        researchRecommendations:JSON.parse(researchRecommendations),
        researchConclusions:JSON.parse(researchConclusions),
    };
    this.props.navigate("/details",{ state: detailsProps });
}



setPaginateFilteredData = () =>{
    this.setState({PaginateFilteredData:this.state.FilteredData.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})})
}

setPage = (e)=>{
    console.log(e)
    this.setState({pageNumber:e}); 
    this.setPaginateFilteredData();
    console.log("pageNumber"+this.state.pageNumber)

}

displayRecordsData = () =>{

    // const Data = this.state.PaginateFilteredData;

    return(
            // Data.slice(pagesVisited, pagesVisited+recordsPerPage).map((item,index)=>(
            this.state.FilteredData.map((item,index)=>(
            <div key={index}>
                <div className="card research-display-card">
                    <div className="card-header">
                        <p className="log-green-text">{getIdFullName(item.researcherName, this.state.ResearchersData)}</p>
                            <div  className="card-details-div">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className="log-green-text details-label">Details</p>
                                        </td>
                                        <td>
                                            <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                onClick={()=>this.goToDetailsScreen(
                                                    item.uploadDate,item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                    item.pdfFile,
                                                    item.researchAbstract,
                                                    item.researchMainObjectives,
                                                    item.researchSpecificObjectives,
                                                    item.researchHypothesis,
                                                    item.researchTheoreticalFrame,
                                                    item.researchLiteratureReview,
                                                    item.researchMethodology,
                                                    item.researchResults,
                                                    item.researchDiscussionOfResults,
                                                    item.researchRecommendations,
                                                    item.researchConclusions,
                                                    item.researchArea,
                                                    item.researchStatus,
                                                    
                                                )}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                    </div>
                    <div className="card-body">
                        <p className='text-black'>{item.researchTopic}</p>
                    </div>
                    <div className="card-footer">
                    <p className='text-black' > Research Status  | <b>{item.researchStatus}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                    </div>
                </div>
            </div>
        ))
    )
}


postUserViewOfResearch =  async (pdfFile,topic,researcher,area,status,uploadDate) => 
    {
        let researchViewer = getCurrentUserId();
        let date = getTodaysDate();
        const payload = {
            
            "uploadDate":uploadDate,
            "pdFile":pdfFile,
            "topic":topic,
            "researcher":researcher,
            "area":parseInt(area),
            "status": status,
            "researchViewer":researchViewer,
            "viewDate":date,
        };
            // console.log("PAYLOAD"+JSON.stringify(payload))

            try
            {
                const postRequest = await axios.post(APIResearchViewsPost,payload)
                let result = postRequest;
                console.log(result.data)
            }
            catch (error)
                {console.log(error)}
    }
render() {
    const {AreasData} =this.state;
    // const SlicedData = this.state.FilteredData.slice(0,3);
    // const pageCount = Math.ceil(this.state.FilteredData.length / this.state.recordsPerPage);

    // const {FilteredData} = this.props.researchesData

return (
            <div className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Researches </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Link to={"/submit"} >
                                    <Button className='black-bg-color' >
                                        Submit Research
                                    </Button>
                                </Link>
                            </ButtonGroup> 
                        </div>
                    </div>
                    <div className="row">
                        <div className='drop-down-researches-div'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Select Research Area
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item> */}
                                    {AreasData && AreasData.map((item, index)=>(
                                        <Dropdown.Item key={index} onClick={()=>this.filterDataByArea(item.id)}>
                                            <label  > {item.name}</label>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                    {this.displayRecordsData()}

                                    {/* <ReactPaginate 
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={Math.ceil(this.state.FilteredData.length / this.state.recordsPerPage)}
                                        onPageChange={(event) => this.setPage(event.selected)}

                                        containerClassName={"paginationButtons"}
                                        previousClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* End col */}
                </div>
            </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Researches);
