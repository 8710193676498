

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';

import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIResearcherForgotPassword } from '../Utilities/APIS';
import { EmailRequired, PostError, validateEmailText } from '../Utilities/Errors';


class ForgetPassword extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        UserEmail:'',
        emailError:'',
        AllInputsRequired:'',
        PasswordResetLinkResponse:'',
    }    
}

componentDidMount(){

}

setEmailTextInput = (text) =>{this.setState({UserEmail:text.target.value})}

validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

sendUserEmail = async () =>{
    let email = this.state.UserEmail

    if (email.length === 0)
    {
        this.setState({AllInputsRequired:EmailRequired}); 
    }
    
    else
    {
        if (this.validateEmail(email)) 
            {
                try
                    {
                        const postRequest = await axios.post(APIResearcherForgotPassword,{
                            "email": email, 
                        },)
                        
                        let result = postRequest.data;
                        // console.log(result)
                        this.setState({PasswordResetLinkResponse:result});
                    }
                    catch (error)
                        {
                            console.log("===========>>>>>>>>>>"+error)
                            alert(PostError)
                        };
            } 
        
        else {this.setState({emailError: validateEmailText});}
    }
}

        
goToLogIn = () =>{this.props.navigate("/");}

render() {
const {UserEmail, AllInputsRequired,emailError,PasswordResetLinkResponse} =this.state;
return (
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100">
                <span className="mask bg-gradient-dark opacity-6"></span>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-12 mx-auto">
                                <br/><br/><br/><br/>
                                <div className="card z-index-0 fadeIn3 fadeInBottom">
                                    <div className="card-body">
                                        <center>
                                            {/* <br/>
                                            <img src={Logo} alt='logo-icon' className="logo-icon" /> */}
                                            <br/>
                                            {/* <h4><b> Forgot Password !</b></h4> */}
                                            <h5 className='custom-red-color'><b>RIA</b></h5>
                                            <h5 className='custom-red-color'><b>Forgot Password !</b></h5>
                                            {/* <h5 className='custom-red-color' ><b>RIA</b></h5> */}
                                            <h6><b>Password Rest Link Will </b></h6>
                                            <h6><b>Be SenT To Your Email</b></h6>
                                        </center>
                                        <div>
                                            <p className="primary-text" >Entre Your Email</p>
                                            <input type="text"  className="form-control form-inputs"  autoComplete="off" placeholder="Email"
                                                value={UserEmail}
                                                onChange={text => this.setEmailTextInput(text)}
                                            />
                                            <p className="primary-text red-text" >{AllInputsRequired}</p>
                                            <p className="primary-text red-text" >{emailError}</p>
                                            <p className="primary-text red-text" >{PasswordResetLinkResponse}</p>
                                        </div>

                                        <div className="text-center">
                                        <button onClick={()=>this.sendUserEmail()} className="btn btn-primary custom-bg-primary custom-text-white" style={{width:"80%"}}> Forget Password </button>

                                        <br/><br/>
                                        <center><p><b>-- OR --</b></p></center>
                                        <button onClick={()=>this.goToLogIn()} className="btn btn-success custom-bg-primary custom-text-white" style={{width:"80%"}}> Log In </button>
                                        </div><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </main>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}



// Wrap the class component with the functional component to get access to navigate
export default withNavigation(ForgetPassword);