
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// redux 
import {Provider} from "react-redux";
import store from './Components/Store/store';

// User
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import LogIn from "./Components/Auth/LogIn";
import Register from "./Components/Auth/Register";
import Dashboard from "./Components/Pages/Dashboard";
import Researches from "./Components/Pages/Researches";
import Details from "./Components/Pages/Details";
import Submit from './Components/Pages/Submit';
import Profile from './Components/Pages/Profile';
import Researchers from './Components/Pages/Researchers';
import Projects from './Components/Pages/Projects';
import Viewer from './Components/Pages/Viewer';
import ViewsViewMore from './Components/Pages/ViewsViewMore';
import ViewMore from './Components/Pages/ViewMore';



import PaginateTest from './Components/Pages/PaginateTest';

function App() {
	return (
		<Provider store={store}>
        {/* <Router basename='user' > */}
        <Router>
			<Routes>
					<Route path="/" element={<LogIn/>} />
					<Route path="/dashboard" element={<Dashboard/>} />
					<Route path="/register" element={<Register />} />
					<Route path="/researches" element={<Researches/>} />
					<Route path="/details" element={<Details/>} />
					<Route path="/submit" element={< Submit />} />
					<Route path="/researchers" element={< Researchers />} />
					<Route path="/projects" element={< Projects />} />
					<Route path="/profile" element={< Profile />} />
					<Route path="/viewer" element={< Viewer />} />
					<Route path="/viewMore" element={< ViewMore />} />
					<Route path="/forget-password" element={< ForgetPassword />} />
					<Route path="/reset-password" element={< ResetPassword />} />
					<Route path="/viewsViewMore" element={< ViewsViewMore />} />




					<Route path="/test" element={< PaginateTest />} />
			</Routes>
        </Router>
		</Provider>
		
);}

export default App;
