

import React,  {Component} from 'react';
import axios from 'axios';

// redux 
import { connect } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIEmailsList, APIResearchRegisterPost, APIUniversitiesGet } from '../Utilities/APIS';
import { fetchResearchAreasData, fetchUniversitiesData } from '../Actions/actions';
import { passwordRegex } from '../Utilities/Constants';
import { EmailRegistered, PostError, ValidPasswordText } from '../Utilities/Errors';
// import UserContext from './UserContext';



// export default class Register extends React.Component {
class Register extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
            EmailListData:[],
            show:false,
            FullName:'', Contact:'', Title:'',
            Email:'',
            Nationality:'', University:'',
            
            Password:'',
            ValidPassword:'',
            PasswordRetype:'',
            UniversitiesData:'',
            PasswordDoNotMatch:'',
            AllInputsRequired:'',
            emailError:'',
            EmailRegisteredError:'',
            showA:true,
    }
}

componentDidMount(){
    this.loadData();
}

// static contextType = UserContext
handleClose = () => {this.setState({show:false});}
handleShow = () => {this.setState({show:true});}

setFullNameTextInput = (text) =>{this.setState({FullName:text.target.value})}
setEmailTextInput = (text) =>{this.setState({Email:text.target.value})}
setContactTextInput = (text) =>{this.setState({Contact:text.target.value})}
setTitleTextInput = (text) =>{this.setState({Title:text.target.value})}
setNationalityTextInput = (text) =>{this.setState({Nationality:text.target.value})}
setUniversityTextInput = (text) =>{this.setState({University:text.target.value})}
setPasswordTextInput = (text) =>{this.setState({Password:text.target.value})}
setPasswordRetypeTextInput = (text) =>{this.setState({PasswordRetype:text.target.value})}



loadData = () =>{
    axios.get(APIUniversitiesGet)
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({UniversitiesData:jsonResults})
        })
    .catch(err=>{console.log(err);})

    axios.get(APIEmailsList)
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({EmailListData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}


goToDashboardScreen = (userId,userName) =>{

    let dashboardProps = {
        userId:userId,
        userName:userName,
    };
    this.props.navigate("/dashboard",{ state: dashboardProps });
}

validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

registerUser = async () =>{
    let fullName = this.state.FullName;
    let contact = this.state.Contact;
    let title = this.state.Title;
    let nationality = this.state.Nationality;
    let university = this.state.University;
    let password = this.state.Password
    let email = this.state.Email
    let passwordRetype = this.state.PasswordRetype

    // Check if email is present in the list using includes
    const emailFound = this.state.EmailListData.includes(email);
    if (emailFound){this.setState({EmailRegisteredError:EmailRegistered})}
    else
        {
            if (fullName.length === 0 || contact.length ===0 || title.length ===0 || nationality.length === 0 || university.length === 0 || email.length === 0)
                {this.setState({AllInputsRequired:"All Inputs Are Required"});}
            else
            {
                if (this.validateEmail(email)) 
                    {
                        if (!passwordRegex.test(password)) {
                            this.setState({ValidPassword:ValidPasswordText});
                        }
                        else 
                        {
                            if (password !== passwordRetype)
                                { 
                                    this.setState({PasswordDoNotMatch:"Passwords Do Not Match"}); 
                                }
                    
                            else
                                {
                                    try
                                        {
                                            const postRequest = await axios.post(APIResearchRegisterPost,{
                                                "fullName": fullName, 
                                                "email": email, 
                                                "title":title,
                                                "university": university, 
                                                "password":password,
                                                "contact":contact, 
                                                "nationality":nationality,
                                            },)
                                            
                                            let result = postRequest.data;
                                            let userId = result.id;
                                            // setUserContext({name:fullName,email:email,contact:contact,title:title, university:university,id:userId,loggedIn:true});
                                            let Details = {name:fullName,email:email, nationality:nationality,contact:contact,title:title, university:university,id:userId,loggedIn:true};
                                            localStorage.setItem('UserDetails',JSON.stringify(Details));
                                            this.props.navigate("/dashboard");
                                            // this.goToDashboardScreen(userId);
                                        }
                                        catch (error)
                                            {
                                                console.log("===========>>>>>>>>>>"+error)
                                                alert(PostError)
                                            };
                                }
                        }
                    } 
                
                else {this.setState({emailError: 'Please Enter A Valid Email Address.'});}
            }
        }
}

        
render() {
    const {
            FullName, Contact, Title, Nationality, University, Password,PasswordRetype,ValidPassword,
            PasswordDoNotMatch, UniversitiesData,show,Email, AllInputsRequired,emailError,EmailRegisteredError,
        } =this.state;
return (
        <main>
            <div className="row">
                <div className="col-lg-7 col-md-8 col-12 mx-auto">
                <br/><br/><br/><br/>
                <div className="card">
                    <div className="card-body">
                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                            <strong>Register For  RIA</strong> Account Today !!!!
                            <div  className='reg-float'>
                            <div>
                                {/* <Button variant="danger" onClick={()=>this.handleShow()}>
                                    <p>Why Registering</p>
                                </Button> */}
                                <button className='btn btn-danger why-registering-btn' onClick={()=>this.handleShow()} > Why Registering ?</button>
                            </div>
                            </div>
                        </div>
                            <div className="register-grid-container-2-columns">
                                <div className="my-grid-item">
                                    <label className="primary-text">Email *</label>
                                    <input type="text"  className="form-control form-inputs"  autoComplete="off" placeholder="Email"
                                        value={Email}
                                        onChange={text => this.setEmailTextInput(text)}
                                    />
                                    <p className="primary-text red-text" >{emailError}</p>
                                    <p className="primary-text red-text">{EmailRegisteredError}</p>
                                </div>
                                <div className="my-grid-item">
                                    <label className="primary-text">Full Name *</label>
                                    <input type="text"  className="form-control form-inputs"  autoComplete="off" placeholder="Full Name"
                                        value={FullName}
                                        onChange={text => this.setFullNameTextInput(text)}
                                    />
                                </div>
                                <div className="my-grid-item">
                                    <label className="primary-text">Contact *</label>
                                    <input type="text"  className="form-control form-inputs" autoComplete="off" placeholder="Contact" 
                                        value={Contact}
                                        onChange={text => this.setContactTextInput(text)}
                                    />
                                </div>
                
                                <div className="my-grid-item">
                                    <label className="primary-text">Title *</label>
                                    <select className="form-control form-inputs"
                                        value={Title}
                                        onChange={text => this.setTitleTextInput(text)}
                                    >
                                        <option value="Dr" >Dr</option>
                                        <option value="Mr" >Mr</option>
                                        <option value="Ms" >Ms</option>
                                        <option value="Sr" >Sr</option>
                                        <option value="Prof" >Prof</option>
                                        <option value="Other" >Other</option>
                                    </select>
                                </div>
                                <div className="my-grid-item">
                                    <label className="primary-text">Nationality *</label>
                                    <input type="text"  className="form-control form-inputs" autoComplete="off" placeholder="Nationality" 
                                        value={Nationality}
                                        onChange={text => this.setNationalityTextInput(text)}
                                    />
                                </div>
                                <div className="my-grid-item">
                                    <label className="primary-text">University / Institution  *</label>
                                    <select className="form-control form-inputs"
                                        value={University}
                                        onChange={text => this.setUniversityTextInput(text)}
                                    >
                                        <option> Select </option>
                                        {UniversitiesData && UniversitiesData.map((item, index) => (<option key={index} value={item.id}> {item.name}</option>))}
                                    </select>
                                </div>
                
                                <div className="my-grid-item">
                                    <label className="primary-text">Password</label>
                                    <input type="password"  className="form-control form-inputs" autoComplete="off" placeholder="Eg: User@2024"
                                        value={Password}
                                        onChange={text => this.setPasswordTextInput(text)}
                                    />
                                    <p className="primary-text red-text" >{ValidPassword}</p>
                                    <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                </div>
                                <div className="my-grid-item">
                                    <label className="primary-text">Confirm Password</label>
                                    <input type="password"  className="form-control form-inputs" autoComplete="off" placeholder="**************" 
                                        value={PasswordRetype}
                                        onChange={text => this.setPasswordRetypeTextInput(text)}
                                    />
                                    <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                </div>
                                <div className="my-grid-item">
                                    <br/><br/>   
                                    <button  onClick={()=>this.registerUser()} className="btn btn-primary" style={{width:"80%"}}>Register Now</button>
                                    <p className="primary-text red-text" >{AllInputsRequired}</p>

                                </div>
                                <div className="my-grid-item">
                                    <br/><br/>   
                                    <Link to={"/"}>
                                        <button   className="btn btn-success" style={{width:"80%"}}>Log In Now</button>
                                    </Link>
                                </div>
                                
                            </div>
                            <Modal show={show} onHide={()=>this.handleClose()}>
                                <Modal.Header>
                                <Modal.Title>Why Registering ????</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                    <p className="primary-text">Not Limited With Application Features</p>
                                    <p className="primary-text">Able To Submit Your Research</p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>
                    </div>
                </div>
    
                </div>
            </div>
        </main>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// redux 
const mapStateToProps = (state)=>{return{}}
const mapDispatchToProps = (dispatch)=>{
    return{
            // Make API Calls
            fetchResearchAreasData : () => dispatch(fetchResearchAreasData()),
            fetchUniversitiesData : () => dispatch(fetchUniversitiesData()),
            // fetchResearchesData: () => dispatch(fetchResearchesData())
    }
}

// Wrap the class component with the functional component to get access to navigate
export default connect(mapDispatchToProps, mapDispatchToProps)(withNavigation(Register));