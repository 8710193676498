import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { clearLocalStorageData } from '../Utilities/Functions';

function Header() {
    // const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState(null);
    



    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let userName = jsonData.name;
            let profileImage = jsonData.profileImage;
            setUserName(userName);
            setUserProfileImage(profileImage);

        }
    }, []);

    const logout = () => {
        clearLocalStorageData();
        navigate('/');
    };


    return (
        <>
            {/* <Nav variant="pills" activeKey="1" onSelect={handleSelect}> */}
            <Nav variant="pills" activeKey="1" >
                <Nav.Item>
                    <Nav.Link eventKey="1"  as={Link} to="/dashboard">
                        Dashboard
                    </Nav.Link>
                </Nav.Item> &nbsp;&nbsp;

                <NavDropdown title="Research" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">
                        <Link to="/submit"> Submit</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                        <Link to="/researches"> Researches</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                        <Link to="/researchers"> Researchers</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                </NavDropdown>
                
                <NavDropdown title="Projects" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">
                        <Link to="/projects"> Projects</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                        <Link to="/projects"> Innovations</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                </NavDropdown>
            </Nav>
            <div className='user-profile-div'>
                <div className='my-grid-container-2-columns'>
                    <div className='my-grid-item-user'>
                        <img src={userProfileImage} alt="Profile" className=" user-icon" />
                    </div>
                    <div className='my-grid-item-user'>
                    <NavDropdown title={userName} id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.2" as={Link} to="/profile" >Profile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" onClick={logout} >Log Out </NavDropdown.Item>
                        <NavDropdown.Divider />
                    </NavDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;