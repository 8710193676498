

import React,{Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../Utilities/assets/css/style.css';


import Header from './Header';
import {  APIResearchersList} from '../Utilities/APIS';
import {getIdCategory } from '../Utilities/Functions';


class Researchers extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchersData:[],
        UniversitiesData:[],
    }    
}

componentDidMount(){
    this.loadData();
    this.initializeReduxData();
}
initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});
    }
};
loadData = () =>{

    axios.get(APIResearchersList)
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}


        
render() {
    const {ResearchersData,UniversitiesData } =this.state;

return (
            <div className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Researchers </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            {/* <ButtonGroup className="mb-2">
                                <Button variant="primary" onClick={()=>this.handleShow()}>
                                    Add New
                                </Button>
                            </ButtonGroup> */}
                            </div>
                    </div>
                    <div className="row">
                        <section className="section profile">
                            <div className="researchers-grid-container">
                                {ResearchersData && ResearchersData.map((item, index) => (
                                    <div key={index} className="researchers-grid-item">
                                        <div className="card researchers-card">
                                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                            <img src={item.profileImage} alt="Profile" className="rounded-circle" />
                                            <h3 className='white-text'>{item.title} {item.fullName}</h3>
                                            <h3 className='white-text'>{item.nationality}</h3>
                                            <h3 className='white-text'>{getIdCategory(item.university, UniversitiesData)}</h3>
                                            <h3 className='white-text'>{item.level}</h3>
                                            <h3 className='white-text'>{item.contact}</h3>
                                            <div className="social-links mt-2">
                                                <Link to={item.twitter} className="twitter"><i className="bi bi-twitter white-text"></i></Link>
                                                <Link to={item.facebook} className="facebook"><i className="bi bi-facebook white-text"></i></Link>
                                                <Link to={item.instagram} className="instagram"><i className="bi bi-instagram white-text"></i></Link>
                                                <Link to={item.linkedin} className="linkedin"><i className="bi bi-linkedin white-text"></i></Link>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Researchers);
